import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import 'styles/admin/loadOrders.scss';

import Layout from 'components/shared/layout';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import SearchInput from 'components/shared/searchInput';
import ErrorComponent from 'components/shared/error';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

import RightArrow from 'assets/images/right-arrow-white-icon.svg';
import TumbleweedIcon from 'assets/images/tumbleweed.gif';

import { Order } from 'types/order';
import { LocationState } from 'types/locationState';
import { get, patch, post, ResponseType, ErrorType } from 'utils/networks';
import { capitalizeFirstLetter, truncateText, isResponseType } from 'utils';
import { TestIds } from 'utils/testing/testIds';
import { APIS, ORDER_STATUSES, ROUTES } from 'constants/index';

const { LOAD_ORDER_ID, LOAD_ORDER_CUSTOMER_NAME } = TestIds;

export default function LoadOrders() {
  const navigate = useNavigate();
  const [isListEmpty, setIsListEmpty] = useState(false);

  const [search, setSearch] = useState<string>('');
  const location: LocationState = useLocation();
  const [orders, setOrders] = useState<Order[]>([]);
  const { logo } = useAccounts();
  const [totalPagesCount, setTotalPageCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [nextPage, setNextPage] = useState<number>(0);
  const [ordersPerPage, setOrdersPerPage] = useState<number>(60);
  const [loading, setLoading] = useState<boolean>(false);

  const autoAllocateLocker = async (id: string, order_id: string, customer_name: string) => {
    const lockerTagsResponse = await get(APIS.admin.lockerTags(id));
    const locationResponse = await get(APIS.admin.locations);

    if (lockerTagsResponse.data.tags.length > 1 || locationResponse.data.length > 1) {
      navigate(ROUTES.admin.autoAllocateLocker, {
        state: {
          id: id,
          orderId: order_id,
          customerName: customer_name,
          previousRoute: 'loadOrder'
        }
      });
    } else {
      const response = await patch(APIS.admin.autoAllocateLockerToOrder(id));

      if (response.ok) {
        navigate(ROUTES.admin.openLockerAdmin, {
          state: {
            id: id,
            orderId: order_id,
            customerName: customer_name,
            lockerNum: response?.data?.locker_no,
            previousRoute: 'loadOrder'
          }
        });
      }
    }
  };

  const handleOnClick = async (
    id: string,
    order_id: string,
    customer_name: string,
    auto_locker_selection: boolean
  ) => {
    const response = await post(APIS.admin.loadSession(id));
    if (response.ok) {
      if (auto_locker_selection) {
        autoAllocateLocker(id, order_id, customer_name);
      } else {
        navigate(ROUTES.admin.chooseLocker, {
          state: { id, orderId: order_id, customerName: customer_name }
        });
      }
    }
  };

  const getOrders = async (query: string, load_more = false) => {
    setLoading(true);
    const params = load_more
      ? {
          query: query,
          status: ORDER_STATUSES.pending,
          page: nextPage,
          per_page: ordersPerPage
        }
      : {
          query: query,
          status: ORDER_STATUSES.pending
        };

    const newParams = queryString.stringify(params);

    const response: ResponseType | ErrorType = await get(`${APIS.admin.orders}?${newParams}`);

    if (isResponseType(response) && response.ok) {
      setOrders((prevOrders) => [...prevOrders, ...response.data]);
      setTotalPageCount(parseInt(response.headers['x-total-pages'] || '0', 10));
      setOrdersPerPage(parseInt(response.headers['x-per-page'] || '0', 10));
      setNextPage(parseInt(response.headers['x-next-page'] || '0', 10));
      setCurrentPage(parseInt(response.headers['x-page'] || '0', 10));

      if (!response.data?.length) {
        setIsListEmpty(true);
        setLoading(false);
        return;
      }
      setIsListEmpty(false);
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(
    debounce((q: string) => getOrders(q), 500),
    []
  );

  const revertOrderLocker = useCallback(async (id: any) => {
    const response = await get(APIS.admin.order(id as string));

    if (response.data.status.toLowerCase().replaceAll(' ', '_') == ORDER_STATUSES.new_pending) {
      await patch(APIS.admin.revertOrderLocker(id));
    }
    localStorage.removeItem('previousPath');
    localStorage.removeItem('orderID');
  }, []);

  useEffect(() => {
    const previousPath = localStorage.getItem('previousPath');
    const orderIdToRevert = localStorage.getItem('orderID');
    if (orderIdToRevert && previousPath && previousPath.includes('/admin/locker/open')) {
      revertOrderLocker(orderIdToRevert);
    }
    return () => {
      if (localStorage.previousPath || localStorage.orderID) {
        localStorage.removeItem('previousPath');
        localStorage.removeItem('orderID');
      }
    };
  }, [revertOrderLocker]);

  useEffect(() => {
    setOrders([]);
    delayedSearch(search);
  }, [delayedSearch, search]);

  return (
    <Layout
      backIcon={true}
      onBackClick={
        location.state?.orderLoaded
          ? () => navigate(ROUTES.admin.dashboard)
          : () => navigate(ROUTES.admin.dashboard)
      }
      navigateToHome={true}
      logoIcon={logo}>
      <div className="load-orders">
        <SearchInput
          value={search}
          placeholder="Search order by name, id..."
          handleChange={(e: string) => setSearch(e)}
        />

        {orders.map(
          ({ id, order_id, customer_name, auto_locker_selection, active_load_session }) => (
            <div key={id} className="order">
              <span>
                <div data-testid={`${LOAD_ORDER_ID}_${id}`} className="order-id">
                  {truncateText(order_id, 15)}
                </div>
                <div data-testid={`${LOAD_ORDER_CUSTOMER_NAME}_${id}`} className="user-name">
                  {capitalizeFirstLetter(customer_name)}
                </div>
              </span>
              <PrimaryButton
                className="load-button"
                onClick={() => handleOnClick(id, order_id, customer_name, auto_locker_selection)}>
                <span className="align-center">
                  {active_load_session ? (
                    <span>IP</span>
                  ) : (
                    <>
                      <span>Load</span> <img src={RightArrow} alt="" className="right-arrow" />
                    </>
                  )}
                </span>
              </PrimaryButton>
            </div>
          )
        )}

        {isListEmpty && (
          <ErrorComponent
            img={TumbleweedIcon}
            heading="Load Order List Empty"
            error="You’re all good. There is nothing to load."
          />
        )}

        {totalPagesCount > 1 && currentPage < totalPagesCount && orders.length > 1 && (
          <PrimaryButton
            className={`load-more`}
            loading={loading}
            onClick={() => getOrders(search, true)}>
            Load More
          </PrimaryButton>
        )}
      </div>
    </Layout>
  );
}
